<template>
  <div>
    <v-container>
      <h1 class="pb-6">Satellite Reports</h1>
      <v-row>
        <v-col v-for="(menu, idx) in menus" :key="idx" cols="6" md="3" sm="3">
          <v-card ripple link :to="'/' + menu.url" rounded="lg" class="pa-6">
            <v-img class="ma-6" :src="getImage(menu.img)"></v-img>
            <p class="text-center">{{ menu.title }}</p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      menus: [
        {
          img: "ndvi.svg",
          title: "NDVI",
        },
        {
          img: "color_composition_maps.svg",
          title: "Color Composition Maps",
        },
        {
          img: "vegetation_time_series.svg",
          title: "Vegetation Time Series",
        },
        {
          img: "burnt_crop_index.svg",
          title: "Burnt Crop Index",
        },
        {
          img: "yield_variability_and_yield_goal_map.svg",
          title: "Yield Variability and Yield Goal Map",
        },
        {
          img: "organic_matter_map.svg",
          title: "Organic Matter Map",
        },
        {
          img: "satellite_derived_management_zones.svg",
          title: "Satellite Derived Management Zones",
        },
        {
          img: "elevation_and_slopes_map.svg",
          title: "Elevation and Slopes Map",
        },
        {
          img: "nitrogen_application_maps.svg",
          title: "Nitrogen Application Maps",
        },
        {
          img: "field_variability_indicator.svg",
          title: "Field Variability Indicator",
        },
        {
          img: "fields_benchmarking.svg",
          title: "Fields Benchmarking",
        },
        {
          img: "difference_map_with_change_detection.svg",
          title: "Difference Map with Change Detection",
        },
        {
          img: "agro_climatic_alerts.svg",
          title: "Agro Climatic Alerts",
        },
        {
          img: "vigor_status_change.svg",
          title: "Vigor Status Change",
        },
        {
          img: "harvest_date.svg",
          title: "Harvest Date",
        },
        {
          img: "crop_emergence_date.svg",
          title: "Crop Emergence Date",
        },
        {
          img: "crop_flowering_date.svg",
          title: "Crop Flowering Date",
        },
        {
          img: "yield_trend_update.svg",
          title: "Yield Trend Update",
        },
      ],
    };
  },
  methods: {
    getImage(img) {
      return "/img/my_reports/satellite_reports/" + img;
    },
  },
};
</script>
